#modal-window {
  position: fixed;
  overflow-y: scroll;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(221, 221, 221, 0.8);
  z-index: 4;
}

#modal-content {
  width: 90%;
  margin: 60px auto;
  background-color: #fff;
  border: 1px solid #6c757d;
  border-radius: 8px;
  padding: 10px 10px 20px;
}

#modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.modal-name,
.close-modal {
  font-size: 16px;
  font-weight: 700;
}

.modal-desc {
  font-weight: 500;
  line-height: 1.5;
}

@media screen and (max-width: 480px) {
  .modal-desc {
    font-size: 13px;
  }
}
