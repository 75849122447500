#missions-section {
  margin: 80px 30px 0;
}

table {
  font-family: 'Montserrat', sans-serif;
  border-collapse: collapse;
}

td,
th {
  border: 1px solid #ddd;
  text-align: left;
  padding: 12px;
}

th {
  font-size: 15px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.mission-name {
  font-weight: bold;
  font-size: 15px;
}

.see-more {
  display: none;
}

.mission-desc {
  line-height: 1.4;
}

.status,
.join-mission {
  width: 150px;
  text-align: center;
}

.status span {
  background-color: #6d757d;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
}

.join-btn {
  cursor: pointer;
  background-color: transparent;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid;
  border-radius: 4px;
  padding: 8px;
}

@media screen and (max-width: 900px) {
  th,
  .mission-name {
    font-size: 13px;
  }

  .mission-desc {
    font-size: 12px;
  }

  .status span {
    font-size: 10px;
  }

  .join-btn {
    font-size: 12px;
  }

  .status,
  .join-mission {
    width: 100px;
  }
}

@media screen and (max-width: 600px) {
  .mission-desc,
  .desc-header {
    display: none;
  }

  .see-more {
    cursor: pointer;
    display: block;
    font-family: inherit;
    font-weight: 400;
    color: #2088ff;
    text-decoration: underline;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin-top: 8px;
  }
}

@media screen and (max-width: 480px) {
  #missions-section {
    margin: 10px;
  }

  td,
  th {
    padding: 8px;
  }

  th,
  .mission-name {
    font-size: 12px;
  }

  .mission-desc {
    font-size: 11px;
  }

  .status span {
    font-size: 9px;
    padding: 2px 6px;
  }

  .join-btn {
    font-size: 11px;
    padding: 5px;
  }

  .status,
  .join-mission {
    width: 100px;
  }
}

@media screen and (max-width: 320px) {
  td,
  th {
    padding: 8px;
  }

  th,
  .mission-name,
  .see-more {
    font-size: 11px;
  }

  .status span {
    font-size: 7px;
  }

  .join-btn {
    font-size: 9px;
  }
}
